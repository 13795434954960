<template>
  <div
    :class="{ editing: showEditor, 'add-asterisk': required, 'FroalaWrapper--immersive': isImmersive, 'FroalaWrapper--hasEditIcon': showEditIcon}"
    :style="computedStyles"
    class="FroalaWrapper"
    @focus="focus"
    @click="handleWrapperClick"
  >
    <div
      v-if="!showEditor"
      class="FroalaWrapper__preview is-position-relative"
    >
      <div
        v-if="showEditIcon"
        class="FroalaWrapper__editIcon"
      >
        <v-icon type="pencil" />
      </div>
      <div
        v-if="showEmptyState"
        class="fr__empty fr-preview"
      >
        <slot name="emptyTextPlaceholder">
          {{ emptyTextPlaceholder || getConfig.placeholderText }}
        </slot>
      </div>
      <froala-view
        v-else
        v-model="model"
        class="fr-preview"
      />
    </div>
    <div
      v-if="showEditor && floatingLabel"
      :class="{ 'FroalaWrapper__floatingLabel--visible': hasValue }"
      class="FroalaWrapper__floatingLabel"
    >
      {{ floatingLabel }}
    </div>
    <froala
      v-model="model"
      :on-manual-controller-ready="initFroala"
      :tag="tag"
      :config="getConfig"
    />
  </div>
</template>
<script>
import _merge from 'lodash/merge'
import { load } from '@hypefactors/shared/js/utils'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
import { froalaEditor, froalaView } from 'vue-froala-wysiwyg'

window.jQuery = window.$ = require('jquery')
// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')

const froalaKey = 'iC1I2E2yD4C3C1C3C4C1A1H4B1D2lykcqswtlvhhG3C-8qr=='

export default {
  components: {
    froala: froalaEditor,
    froalaView: froalaView
  },
  props: {
    value: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: () => ({})
    },
    showEditor: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'textarea'
    },
    floatingLabel: {
      type: String,
      default: ''
    },
    showEditIcon: {
      type: Boolean,
      default: false
    },
    isImmersive: {
      type: Boolean,
      default: false
    },
    froalaId: {
      type: String,
      default: ''
    },
    emptyTextPlaceholder: {
      type: String,
      default: ''
    },
    addMinHeight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseConfig: {
        charCounterCount: false,
        key: froalaKey,
        pluginsEnabled: ['quickInsert'],
        toolbarButtons: ['bold'],
        htmlAllowedTags: ['a', 'article', 'aside', 'b', 'blockquote', 'br', 'caption', 'cite', 'dd', 'div', 'dl', 'dt', 'em', 'figcaption', 'figure', 'footer', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'img', 'li', 'ol', 'p', 'pre', 'section', 'small', 'span', 'strike', 'strong', 'sub', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'tr', 'u', 'ul'],
        // eslint-disable-next-line no-undef
        enter: $.FroalaEditor.ENTER_BR,
        events: {
          'froalaEditor.initialized': (e, editor) => {
            this.instance = editor
            editor.el.id = this.froalaId || this.$attrs.name
          },
          'froalaEditor.blur': () => {
            this.hideEditor()
            this.$emit('blur')
          }
        },
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        requestHeaders: {
          Authorization: `Bearer ${load('access_token', '')}`,
          'Upload-Image-Type': 'StoryInline'
        },
        imageUploadParam: 'image',
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageAlt', 'imageSize']
      },
      controls: null,
      instance: null,
      elementHeight: 0
    }
  },
  computed: {
    getConfig () {
      return _merge({
        imageUploadURL: this.uploadUrl
      }, this.baseConfig, this.config)
    },
    uploadUrl () {
      return buildUrlWithParams(this.$getUrl('/upload/images', 'api'), {
        responseType: 'froala',
        brand: this.activeBrandId
      })
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change')
      }
    },
    showEmptyState () {
      return !this.showEditor && !this.model
    },
    computedStyles () {
      if (!this.addMinHeight) return {}
      return {
        minHeight: `${this.elementHeight}px`
      }
    },
    hasValue () {
      return !!this.model
    }
  },
  watch: {
    showEditor (val) {
      if (val === true) {
        this.$nextTick(() => {
          this.focusEditor()
        })
      }
    }
  },
  mounted () {
    this.storeHeight()
  },
  methods: {
    initFroala (controls) {
      this.controls = controls
      this.controls.initialize()
    },
    hideEditor () {
      if (!this.showEditor) return
      this.$emit('update:showEditor', false)
    },
    revealEditor () {
      if (this.showEditor) {
        return this.focusEditor()
      }
      this.$emit('update:showEditor', true)
    },
    focusEditor () {
      this.controls.getEditor()('events.focus')
    },
    focus () {
      if (this.showEditor) return
      this.revealEditor()
    },
    handleWrapperClick () {
      if (this.showEditor) return
      this.revealEditor()
    },

    async storeHeight () {
      if (!this.addMinHeight) return
      await this.$nextTick()
      this.elementHeight = this.$el ? this.$el.clientHeight : 0
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

$fa-font-path: '~font-awesome/fonts/';
// Require Froala Editor css files.
@import '~froala-editor/css/froala_editor.pkgd.min';
@import '~font-awesome/scss/font-awesome';
@import '~froala-editor/css/froala_style.min';

.FroalaWrapper {
  &__floatingLabel {
    position: absolute;
    top: -5px;
    text-transform: uppercase;
    font-weight: normal;
    color: $grey;
    font-size: $hf__font-label;
    transition: .3s;
    opacity: 0;

    &--visible {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &__editIcon {
    color: $primary;
    position: absolute;
    right: -1.5rem;
    top: 0;
    font-size: 1rem;
  }

  &--immersive {
    .fr-wrapper {
      background: transparent !important;

      .fr-element.fr-view {
        padding: 0;
      }
    }
  }

  &--hasEditIcon {
    padding-right: 1.6rem;
  }

  &.editing {
    .fr-box {
      display: block;
    }

    > .fr-view, > .fr-preview {
      display: none;
    }
  }

  /* Froala overrides */

  .fr-view {
    a:not(.fr---skip-styling) {
      text-decoration: underline;
      color: $primary;
    }
  }

  .fr-box {
    display: none;
  }

  > .fr-view, > .fr-preview, .fr__empty {
    display: block;
    color: $grey;
    cursor: pointer;
  }

  .fr-wrapper {
    box-shadow: none !important;

    .fr-element.fr-view {
      min-height: 2rem;
    }
  }

  .fr-toolbar {
    box-shadow: none !important;
    border-top: none !important;
    background: $grey-light;
  }
}
</style>
